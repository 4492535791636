import {computed} from 'mobx'
import IParty from '../domain/IParty'
import IProduct from '../domain/IProduct'
import {isProductOnSale, isProductOperational} from '../helpers/product'
import IContentService from '../services/IContentService'
import RootStore from './index'
import ResourceStore, {RelationType} from './ResourceStore'

enum Keys {
  MANAGED = 'MANAGED',
  VISIBLE = 'VISIBLE',
}

export default class ProductStore extends ResourceStore<IProduct> {
  private readonly rootStore: RootStore
  private readonly contentService: IContentService

  public constructor(rootStore: RootStore, contentService: IContentService) {
    super()
    this.rootStore = rootStore
    this.contentService = contentService
  }

  public async loadManagedProduct(id: any) {
    await this.setOneEventually(this.contentService.getProductManagedByParty(this.party.id, id))
  }

  public async loadVisibleProduct(id: any) {
    await this.setOneEventually(this.contentService.getProductVisibleToParty(this.party.id, id))
  }

  @computed
  public get managedProducts(): IProduct[] {
    return this.getItemsByKey(Keys.MANAGED)
  }

  @computed
  public get visibleProducts(): IProduct[] {
    return this.getItemsByKey(Keys.VISIBLE)
  }

  @computed
  public get onSaleProducts(): IProduct[] {
    return this.visibleProducts && this.visibleProducts.filter(isProductOnSale)
  }

  @computed
  public get onSaleOperationalProducts(): IProduct[] {
    return this.visibleProducts && this.visibleProducts.filter(isProductOperational)
  }

  @computed
  private get party(): IParty {
    return this.rootStore.profileStore.party
  }
}
