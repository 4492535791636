import Auth from '@aws-amplify/auth'
import IUser from 'domain/IUser'
import useServices from 'hooks/useServices'
import React from 'react'
import {useHistory} from 'react-router'
import useLocalization from '../../../hooks/useLocalization'
import useStore from '../../../hooks/useStore'
import Box from '../../Box'
import Form from '../../Form'
import {StyledFormButton, StyledFormCheckbox} from './Layout/FormComponents'
import FormInput from 'components/Form/FormInput'
import useAlerts from 'hooks/useAlerts'

interface IProps extends React.PropsWithChildren {
  accessCode: string
  user: IUser
}

const OfferQuickSignInForm: React.FC<IProps> = ({accessCode, user}) => {
  const {profileStore} = useStore()
  const {addError} = useAlerts()
  const {translate} = useLocalization()
  const history = useHistory()
  const {contentService} = useServices()

  const handleSubmit = async ({firstName, lastName, password}) => {
    try {
      const userData = {firstName, lastName, password}

      const offer = await contentService.setPasswordFromOfferInvite(accessCode, userData)
      await Auth.signIn(user.email, password)
      await profileStore.load()
      history.push(`/consumer/buy/offers/${offer.id}?modal.offerpdf`)
    } catch (err) {
      console.error(err)
      addError(translate('Could not create new password. Please contact an admin.'), err?.correlationId, err?.message)
    }
  }

  return (
    <Form onSubmit={handleSubmit} submissionFeedback={null}>
      <FormInput large disabled type="email" name="email" label={translate('Email')} defaultValue={user.email} />
      <FormInput
        large
        required
        type="text"
        name="firstName"
        label={translate('First name')}
        defaultValue={user.firstName}
      />
      <FormInput
        large
        required
        type="text"
        name="lastName"
        label={translate('Last name')}
        defaultValue={user.lastName}
      />
      <Box>
        <FormInput
          large
          label={translate('Create password')}
          name="password"
          type="password"
          placeholder="********************"
          validate={(value: string): string | true => {
            if (value.length < 8) {
              return translate('Must be at least 8 characters.')
            }

            if (!/[a-z]/.test(value)) {
              return translate('Must contain at least one lowercase character.')
            }

            if (!/[0-9]/.test(value)) {
              return translate('Must contain at least one number.')
            }

            if (!/[A-Z]/.test(value)) {
              return translate('Must contain at least one uppercase character.')
            }

            return true
          }}
          required
        />
      </Box>

      <FormInput
        large
        label={translate('Confirm password')}
        name="confirmPassword"
        type="password"
        placeholder="********************"
        required
        validate={(value, getValues) => value === getValues().password || translate('Passwords do not match')}
      />

      <Box margin={{top: 2}}>
        <StyledFormCheckbox
          name="agreeTerms"
          label={translate('I agree to the privacy policy and terms of the platform')}
          required
        />
        <StyledFormCheckbox
          name="agreeCookies"
          label={translate('I accept the cookie policy of the platform')}
          required
        />
      </Box>

      <Box justify="flex-end" pad={{top: 2}}>
        <StyledFormButton variant="primary">{translate('Sign in')}</StyledFormButton>
      </Box>
    </Form>
  )
}

export default OfferQuickSignInForm
