import IAdminCreatePartyMember from 'domain/IAdminCreateUser'
import {TextDocumentType} from 'domain/IDocumentType'
import {IPartyMemberUpdateRequest} from 'domain/IPartyMemberUpdateRequest'
import {IProposalDetailsBody} from 'domain/IProposalDetails'
import IPurchaseContract from 'domain/IPurchaseContract'
import ITextDocument from 'domain/ITextDocument'
import IUser from 'domain/IUser'
import IAttachment from '../domain/IAttachment'
import IContractType from '../domain/IContractType'
import IOffer, {IOfferInvite} from '../domain/IOffer'
import IParty, {PartyRole} from '../domain/IParty'
import IPartyMember from '../domain/IPartyMember'
import {IPasswordFromInvite} from '../domain/IPasswordFromInvite'
import IProduct from '../domain/IProduct'
import IContentService from './IContentService'
import IHttpClient from './IHttpClient'

export default class LederhosenContentService implements IContentService {
  private readonly httpClient: IHttpClient

  constructor(httpClient: IHttpClient) {
    this.httpClient = httpClient
  }

  public async updateProfile(updatedUser: any) {
    const {data} = await this.httpClient.put<IUser>(`/public/currentuser/profile`, updatedUser)

    return data
  }

  public async createPartyForCurrentUser(partyMember: Omit<IPartyMember, 'id'>): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(`/public/currentuser/party-memberships`, partyMember)

    return data
  }

  public async savePartyForCurrentUser(partyMember: IPartyMember): Promise<IPartyMember> {
    const {data} = await this.httpClient.put<IPartyMember>(`/public/currentuser/party-memberships`, partyMember)

    return data
  }

  public async uploadConsumptionProfile(partyId: any, csv: File, name?: string): Promise<void> {
    const formData = new FormData()
    formData.append('file', csv)

    if (name) {
      formData.append('name', name)
    }

    await this.httpClient.post(`/public/parties/${partyId}/consumption`, formData)
  }

  public async getTextDocument(documentType: TextDocumentType, locale: string = 'en'): Promise<ITextDocument> {
    const {data} = await this.httpClient.get<ITextDocument>(`/public/text-document`, {params: {documentType, locale}})

    return data
  }

  public async getProductVisibleToParty(partyId: any, id: any): Promise<IProduct> {
    const {data} = await this.httpClient.get<IProduct>(`/public/parties/${partyId}/visible-products/${id}`)

    return data
  }

  public async getProductManagedByParty(partyId: any, id: any): Promise<IProduct> {
    const {data} = await this.httpClient.get<IProduct>(`/public/parties/${partyId}/managed-products/${id}`)

    return data
  }

  public async getProductsVisibleToParty(partyId: any): Promise<IProduct[]> {
    const {data} = await this.httpClient.get<IProduct[]>(`/public/parties/${partyId}/visible-products`)

    return data
  }

  public async editCertificateOffer(partyId: any, offerId: any, body: IProposalDetailsBody): Promise<IOffer> {
    const {data} = await this.httpClient.put<IOffer>(
      `/public/parties/${partyId}/offers/${offerId}/purchase-designer`,
      body,
    )

    return data
  }

  public async createProduct(partyId: any, product: IProduct): Promise<IProduct> {
    const {data} = await this.httpClient.post<IProduct>(`/public/parties/${partyId}/products`, product)

    return data
  }

  public async getContractContractTemplatePdf(contractId: any, templateId: any, partyId: any): Promise<Blob> {
    const {data} = await this.httpClient.get(
      `/public/${partyId}/contract-templates/${templateId}/contracts/${contractId}`,
      {
        headers: {
          accept: 'application/pdf',
        },
        responseType: 'blob',
      },
    )

    return new Blob([data])
  }

  public async getContractContractTemplates(contractId: any, partyId: any): Promise<any> {
    const {data} = await this.httpClient.get<IContractType>(
      `/public/${partyId}/contract-templates/contracts/${contractId}`,
    )

    return data
  }

  public async getContractHtml(contractId: any): Promise<string> {
    const {data} = await this.httpClient.get(`/public/contracts/${contractId}`, {
      headers: {
        accept: 'text/html',
      },
    })

    return data
  }

  public async confirmUserPhoneNumber(code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification/confirm?verification_code=${code}`)

    return data
  }

  public async sendUserPhoneVerification(phone: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/phone-verification`, {phone})

    return data
  }

  public async sendContractVerificationCode(contractId: number): Promise<void> {
    const {data} = await this.httpClient.post<void>(`/public/contract-verification?contract=${contractId}`)

    return data
  }

  public async confirmContractVerificationCode(contractId: number, code: string): Promise<void> {
    const {data} = await this.httpClient.post<void>(
      `/public/contract-verification/confirm?contract=${contractId}&code=${code}`,
    )

    return data
  }

  public async getManagedParties(asPartyId: any, partyRole: PartyRole = PartyRole.BUYER): Promise<IParty[]> {
    const params = {'partyRole.equals': partyRole, 'state.notEquals': 'DELETED'}

    const {data} = await this.httpClient.get<IParty[]>(`/public/parties/${asPartyId}/managed-parties`, {
      params,
    })

    return data.map(d => ({...d, partyRole}))
  }

  public async getPartyMembers(asPartyId: number): Promise<IPartyMember[]> {
    const {data} = await this.httpClient.get<IPartyMember[]>(`/public/parties/${asPartyId}/members`)

    return data
  }

  public async getUserFromInvite(accessCode: string): Promise<IUser> {
    const {data} = await this.httpClient.get(`/public/user-invites/${accessCode}`)

    return data as IUser
  }

  public async getUserFromOfferInvite(accessCode: string): Promise<IOfferInvite> {
    const {data} = await this.httpClient.get(`/public/offer-invites/${accessCode}`)

    return data
  }

  public async setPasswordFromInvite(accessCode: string, password: IPasswordFromInvite): Promise<void> {
    await this.httpClient.post(`/public/user-invites/${accessCode}/confirm`, password)
  }

  public async setPasswordFromOfferInvite(
    accessCode: string,
    user: {firstName: string; lastName: string; password: string},
  ): Promise<IOffer> {
    const {data} = await this.httpClient.post(`/public/offer-invites/${accessCode}/confirm`, {
      user,
    })

    return data.offer
  }

  public async resendUserInvite(asPartyId: number, partyMemberId: number): Promise<void> {
    await this.httpClient.post(`/public/parties/${asPartyId}/members/${partyMemberId}/resend-invite`)
  }

  public async getPartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.get(`/public/parties/${asPartyId}/members/${partyMemberId}`)

    return data
  }

  public async createPartyMemberUser(
    asPartyId: number,
    createPartyMember: IAdminCreatePartyMember,
  ): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(`/public/parties/${asPartyId}/members`, createPartyMember)

    return data
  }

  public async updatePartyMember(asPartyId: number, partyMember: IPartyMemberUpdateRequest): Promise<IPartyMember> {
    const {data} = await this.httpClient.put<IPartyMember>(
      `/public/parties/${asPartyId}/members/${partyMember.id}`,
      partyMember,
    )

    return data
  }

  public async disablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(
      `/public/parties/${asPartyId}/members/${partyMemberId}/disable`,
    )

    return data
  }

  public async enablePartyMember(asPartyId: number, partyMemberId: number): Promise<IPartyMember> {
    const {data} = await this.httpClient.post<IPartyMember>(
      `/public/parties/${asPartyId}/members/${partyMemberId}/enable`,
    )

    return data
  }

  public async getManagedParty(asPartyId: any, partyId: any): Promise<IParty> {
    const {data} = await this.httpClient.get<IParty>(`/public/parties/${asPartyId}/managed-parties/${partyId}`)

    return data
  }

  public async createManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    const {data} = await this.httpClient.post<IParty>(`/public/parties/${asPartyId}/managed-parties`, party)

    return data
  }

  public async updateManagedParty(asPartyId: any, party: IParty): Promise<IParty> {
    const {data} = await this.httpClient.put<IParty>(`/public/parties/${asPartyId}/managed-parties`, party)

    return data
  }

  public async softDeleteManagedParty(asPartyId: any): Promise<IParty> {
    const {data} = await this.httpClient.delete<IParty>(`/public/parties/${asPartyId}/managed-parties`)

    return data
  }

  public getAttachmentURL(attachment: IAttachment): string {
    if (!attachment) {
      return ''
    }

    return `${this.httpClient.defaults.baseURL}/public/attachments/download/${attachment.id}-${attachment.fileName}`
  }

  public async getSalesContracts(partyId: any): Promise<IPurchaseContract[]> {
    const {data} = await this.httpClient.get<IPurchaseContract[]>(`/public/parties/${partyId}/sales-contracts`)

    return data
  }
}
