import React from 'react'
import {Route, Switch} from 'react-router'
import NotFound from '../../components/NotFound'
import Layout from '../../components/Platform/Onboarding/Layout'
import useProfile from '../../hooks/useProfile'
import ForgotPassword from './ForgotPassword'
import OfferInvite from './OfferInvite'
import QuickSignIn from './QuickSignIn'
import EmailVerificationForm from 'components/Platform/Onboarding/EmailVerificationForm'

const Onboarding: React.FC<React.PropsWithChildren> = () => {
  const {user} = useProfile()

  return (
    <Layout showLogout={!!user}>
      <Switch>
        <Route exact path="/on-boarding/offer-invite/:accessCode" component={OfferInvite} />
        <Route exact path="/on-boarding/sign-in-by-invite/:accessCode" component={QuickSignIn} />
        <Route exact path="/on-boarding/invite-link/:accessCode" render={() => <QuickSignIn v2 />} />
        <Route exact path="/on-boarding/forgot-password" component={ForgotPassword} />
        <Route exact path="/on-boarding/email-verification/:email" component={EmailVerificationForm} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Layout>
  )
}

export default Onboarding
